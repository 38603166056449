@import 'src/scss/variables/colors';
@import 'src/scss/variables/variables';

.container {
  min-height: 100vh;
  padding: 0 40px;
  position: absolute;
}

.view-container {
  background-color: $white;
  box-shadow: $container-shadow;
  height: 85vh;
  max-width: $content-max-width;
  padding: 10px;
}

.maintenance-logo {
  height: 230px;
  width: 370px;
}

.title-text {
  text-align: center;
}

.small-text {
  text-align: center;
  width: 30%;
}

@media screen and (max-width: $small-device) {
  .container {
    height: auto;
    min-height: 100vh;
    padding: 0 10px;
  }

  .maintenance-logo {
    height: 130px;
    width: 210px;
  }

  .view-container {
    background-color: $white;
    box-shadow: $container-shadow;
    height: auto;
    max-width: $content-max-width;
    padding: 50px 20px;
  }

  .title-text {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .small-text {
    font-size: 20px;
    line-height: 22px;
    width: 100%;
  }
}
