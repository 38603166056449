@import 'src/scss/imports.scss';

$animation-duration: 1.5s;

.container {
  @extend .row, .middle, .space-between, .m-bottom-3;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid $transparent;
  background-color: $success-50;
}

.clickable {
  cursor: pointer;
  &:hover {
    border-color: $ocean-green;
  }
}

.icon-container {
  display: inline-flex;
  position: relative;
}

@mixin icon-decoration($color, $delay) {
  position: absolute;
  animation: {
    name: appear-disappear;
    duration: $animation-duration;
    iteration-count: infinite;
    direction: alternate;
    timing-function: linear;
    delay: $delay;
  }
  path {
    fill: $color;
  }
}

@keyframes appear-disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.yellow-star {
  @include icon-decoration($saffron, 0);
  bottom: 0;
  left: 0;
}

.green-star {
  @include icon-decoration($ocean-green, calc(#{$animation-duration} * 0.8));
  transform: scale(0.5);
  top: 0;
  left: 5px;
}

.red-star {
  @include icon-decoration($pj-red, $animation-duration);
  transform: scale(0.7);
  bottom: 5px;
  right: 0;
}

.pizza-icon {
  min-width: 42px;
}

.description {
  @extend .semi-bold;
  color: $basil-500;
  flex-grow: 1;
  font-weight: 400;

  & span {
    font-size: inherit;
    display: inline;
    color: inherit;
  }
}

.icon-decoration {
  position: absolute;
  top: 0;
  right: 0;
}

.cross-button-container {
  width: auto !important;
  padding: 0 !important;
}

.cross-button-icon {
  border-radius: 50%;
  border: 1px solid $santas-gray;
}

.hidden {
  visibility: hidden;
}

.loading {
  opacity: .7;
  pointer-events: none;
}
