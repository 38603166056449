$grey-50: #ebebeb;
$grey-100: #c0c0c0;
$grey-200: #a1a1a1;
$grey-300: #767676;
$grey-500: #333333;
$grey-900: #151515;

$basil-50: #eaefea;
$basil-100: #becdbd;
$basil-300: #729270;
$basil-500: #2d5d2a;
$basil-700: #20421e;
$basil-900: #132712;

$success-100: #cfe2d6;
$success-500: #65a17a;
$success-700: #487257;
$success-900: #2a4433;

$error-100: #ffb0b0;
$error-500: #ff0000;
$error-700: #b50000;
$error-900: #6b0000;

$pepperoncini-50: #fafde7;
$pepperoncini-100: #f0f9b4;
$pepperoncini-400: #d9ef3d;
$pepperoncini-500: #cfeb0c;
$pepperoncini-700: #93a709;

$warning-50: #fffaf1;
$warning-100: #fff0d2;
$warning-200: #ffe9bc;
$warning-300: #ffdf9e;
$warning-400: #ffd98b;
$warning-500: #ffcf6e;
$warning-600: #e8bc64;
$warning-700: #b5934e;
$warning-800: #8c723d;
$warning-900: #6b572e;

$google: #EB4335;
$facebook: #1877F2;
