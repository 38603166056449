@import 'src/scss/imports';

#container-weglot {
  @extend .row, .middle;
  background-color: $white;
  height: 24px;
  width: 50px;
  border: 1px solid #C0C0C0;
  padding: 4px;
  border-radius: 4px;
}
.visible {
  display: block;
}

.hidden {
  display: none;
}

.wg-drop.country-selector a {
  padding: 0px !important;
  height: auto !important;
  padding: 2px !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: $grey-300;
}

#container-weglot:hover {
  transition: all 290ms cubic-bezier(0.79, 0.01, 0.375, 0.995);
}

div#container-weglot:hover  .wg-drop.country-selector {
  transition: all 290ms cubic-bezier(0.79, 0.01, 0.375, 0.995);
}

.weglot-container, .wg-default, .wg-default .country-selector{
  align-self: center;
}
.country-selector.closed{
  border-radius: 10px;
}

.wg-drop.country-selector .wgcurrent.wg-li {
  @extend .row;
  border: none;
}

.country-selector .wgcurrent:after {
  height: 20px !important;
  position: inherit !important;
  margin-top: -2px;
  background-size: 8px !important;
  width: 8px !important;
  filter: brightness(30%) contrast(20%) saturate(0%) grayscale(100%);
  transform: rotate(360deg) !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpb25pY29uIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZD0iTTk4IDE5MC4wNmwxMzkuNzggMTYzLjEyYTI0IDI0IDAgMDAzNi40NCAwTDQxNCAxOTAuMDZjMTMuMzQtMTUuNTcgMi4yOC0zOS42Mi0xOC4yMi0zOS42MmgtMjc5LjZjLTIwLjUgMC0zMS41NiAyNC4wNS0xOC4xOCAzOS42MnoiLz48L3N2Zz4=) !important;
}

.wg-drop.weg-openleft ul {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.wg-drop.country-selector li {
  padding: 4px;
  padding-left: 10px;
}
@media screen and (max-width: $breakpoint-sm) {
  div#container-weglot {
    margin-right: 0px;
    box-shadow: 0 0 0 0;
    margin-left: auto;
    background-color: inherit !important;
    border: none;
  }
  
  aside.wg-drop.country-selector{
    background-color: inherit !important;
    padding: 0px;
  }

  .country-selector .wgcurrent:after {
    margin-top: 2px;
    background-size: cover;
    filter: brightness(0) invert(1);
  }

  .wg-drop.country-selector .wgcurrent.wg-li {
    margin-top: 10px;
    margin-bottom: 10px ;
  }

  aside.wg-drop.country-selector .wgcurrent a {
    padding: 0px !important;
    height: 40px;
  }
  aside.wg-drop.country-selector a {
    padding: 0px;
    color: $white;
    border: 0;
  }

  aside.wg-drop.country-selector li a {
    padding: 0px;
    color: $ocean-green;
    border: 1px solid $ocean-green;
  }

  .wg-flags a img.wg-flag {
    margin-left: 10px;
  }
}

