@import "src/scss/variables/variables";

@mixin animate($animation) {
  animation: $animation $animation-duration $ease-in-out;
}

%appear {
  animation: appear $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%float {
  animation: float $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%grow {
  animation: grow $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%slide-down {
  animation: slide-down $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%slide-left-with-delay {
  animation: slide-left-with-delay 6s $ease-in-out 4s infinite;
}

%horizontal-shaking{
  animation: horizontal-shaking $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  90% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up-mobile {
  0% {
    transform: translateY(120%);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(40%);
  }
}

@keyframes slide-down-mobile {
  0% {
  }

  100% {
    transform: translateY(120%);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(400px * 1.3);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  100% {
    transform: translateX(400px * 1.3);
  }

  0% {
    transform: translateX(0);
  }
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
