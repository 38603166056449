@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('./icons-fonts/icomoon.eot?mqk4sl');
  src: url('./icons-fonts/icomoon.eot?mqk4sl#iefix') format('embedded-opentype'),
  url('./icons-fonts/icomoon.ttf?mqk4sl') format('truetype'),
  url('./icons-fonts/icomoon.woff?mqk4sl') format('woff'),
  url('./icons-fonts/icomoon.svg?mqk4sl#icomoon') format('svg');
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  text-transform: none;

  &::before {
    color: inherit;
  }
}

.icon-store-open:before {
  content: '\e965';
}

.icon-ic_pino:before {
  content: '\e964';
}

.icon-angle-up:before {
  content: '\e963';
}

.icon-check-off:before {
  content: '\e961';
}

.icon-check-on:before {
  content: '\e962';
}

.icon-calendar:before {
  content: '\e95f';
}

.icon-flash:before {
  content: '\e960';
}

.icon-descount:before {
  content: '\e95d';
}

.icon-piece:before {
  content: '\e95e';
}

.icon-copy:before {
  content: '\e95b';
}

.icon-whatsapp:before {
  content: '\e95c';
}

.icon-build:before {
  content: '\e956';
}

.icon-cc-amex:before {
  content: '\e957';
}

.icon-cc-discovery:before {
  content: '\e958';
}

.icon-cc-mastercard:before {
  content: '\e959';
}

.icon-cc-visa:before {
  content: '\e95a';
}

.icon-switch-off:before {
  content: '\e953';
}

.icon-switch-on:before {
  content: '\e954';
}

.icon-save:before {
  content: '\e955';
}

.icon-info:before {
  content: '\e94f';
}

.icon-person:before {
  content: '\e950';
}

.icon-spicy:before {
  content: '\e951';
}

.icon-hot:before {
  content: '\e96b';
}

.icon-veggie:before {
  content: '\e952';
}

.icon-vegan:before {
  content: '\e96a';
}


.icon-pay-online:before {
  content: '\e94e';
}

.icon-dropdown:before {
  content: '\e917';
}

.icon-close-thick:before {
  content: '\e94c';
}

.icon-close:before {
  content: '\e94d';
}

.icon-facebook-full:before {
  content: '\e946';
}

.icon-facebook-line:before {
  content: '\e947';
}

.icon-instagram-full:before {
  content: '\e948';
}

.icon-instagram-line:before {
  content: '\e949';
}

.icon-twitter-full:before {
  content: '\e94a';
}

.icon-twitter-line:before {
  content: '\e94b';
}

.icon-hamburguer:before {
  content: '\e945';
}

.icon-search:before {
  content: '\e944';
}

.icon-posnet:before {
  content: '\e943';
}

.icon-order-progress:before {
  content: '\e93e';
}

.icon-store-close:before {
  content: '\e93f';
}

.icon-attach:before {
  content: '\e940';
}

.icon-image:before {
  content: '\e941';
}

.icon-question:before {
  content: '\e942';
}

.icon-heart:before {
  content: '\e93d';
}

.icon-error:before {
  content: '\e93c';
}

.icon-check-simple:before {
  content: '\e93a';
}

.icon-trash:before {
  content: '\e93b';
}

.icon-edit:before {
  content: '\e938';
}

.icon-time-full:before {
  content: '\e939';
}

.icon-cookie:before {
  content: '\e935';
}

.icon-cake:before {
  content: '\e936';
}

.icon-star:before {
  content: '\e934';
}

.icon-logout:before {
  content: '\e933';
}

.icon-make-pizza:before {
  content: '\e92f';
}

.icon-pizza-by-halves:before {
  content: '\e932';
}

.icon-check:before {
  content: '\e92d';
}

.icon-cooking:before {
  content: '\e92e';
}

.icon-oven:before {
  content: '\e930';
}

.icon-pickup:before {
  content: '\e931';
}

.icon-payment-cash:before {
  content: '\e92b';
}

.icon-payment-debit-card:before {
  content: '\e92c';
}

.icon-add-circle:before {
  content: '\e910';
}

.icon-add:before {
  content: '\e911';
}

.icon-angle-down:before {
  content: '\e912';
}

.icon-arrow-left:before {
  content: '\e913';
}

.icon-arrow-right:before {
  content: '\e914';
}

.icon-cart:before {
  content: '\e915';
}

.icon-clean-full:before {
  content: '\e916';
}

.icon-delivery-full:before {
  content: '\e918';
}

.icon-dpto:before {
  content: '\e919';
}

.icon-facebook:before {
  content: '\e91a';
}

.icon-google:before {
  content: '\e91b';
}

.icon-house:before {
  content: '\e91c';
}

.icon-instagram:before {
  content: '\e91d';
}

.icon-local-full:before {
  content: '\e91e';
}

.icon-location:before {
  content: '\e91f';
}

.icon-mail:before {
  content: '\e920';
}

.icon-map:before {
  content: '\e921';
}

.icon-note:before {
  content: '\e922';
}

.icon-pass-off:before {
  content: '\e923';
}

.icon-pass-on:before {
  content: '\e924';
}

.icon-password:before {
  content: '\e925';
}

.icon-phone:before {
  content: '\e926';
}

.icon-pin:before {
  content: '\e927';
}

.icon-remove-circle:before {
  content: '\e928';
}

.icon-time:before {
  content: '\e929';
}

.icon-twitter:before {
  content: '\e92a';
}

.icon-user-full:before {
  content: '\e900';
}

.icon-stars-full:before {
  content: '\e901';
}

.icon-pizza:before {
  content: '\e902';
}

.icon-deal:before {
  content: '\e903';
}

.icon-soda:before {
  content: '\e904';
}

.icon-sticks:before {
  content: '\e905';
}

.icon-extra:before {
  content: '\e906';
}

.icon-order-full:before {
  content: '\e907';
}

.icon-menu-full:before {
  content: '\e908';
}

.icon-desert:before {
  content: '\e909';
}

.icon-shop:before {
  content: '\e90a';
}

.icon-menu:before {
  content: '\e90b';
}

.icon-stars:before {
  content: '\e90c';
}

.icon-order:before {
  content: '\e90d';
}

.icon-user:before {
  content: '\e90e';
}

.icon-delivery:before {
  content: '\e90f';
}

.icon-car:before {
  content: '\e966';
}

.icon-car-rounded:before {
  content: '\e967';
}

.icon-tiktok:before {
  content: '\e968';
}

.icon-minus:before {
  content: '\e970';
}

.icon-plus:before {
  content: '\e971';
}

.icon-cancel-circle:before {
  content: '\e972';
}

.icon-exclamation:before {
  content: '\e973';
}

.icon-times:before {
  content: '\e974';
}

.icon-spinner:before {
  content: '\e975';
}
