@import 'src/scss/imports';

$border: 1px solid $skeptic;
$border-radius: 4px;
$transition-duration: .5s;

.title-container {
  @extend .row, .middle, .space-between;
  cursor: pointer;
  padding: 10px 8px;
}

.content-container {
  padding-top: 10px;
}

.container {
  max-height: 0;
  visibility: hidden;
  transition: max-height $transition-duration, visibility 0s;
  border-top-width: 0;

  &.active {
    max-height: 300px;
    visibility: visible;
  }
}

.icon {
  transition: transform $transition-duration;
  transform: rotate(180deg);
  &.active {
    transform: rotate(0deg);
  }
}
