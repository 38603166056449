@import 'variables/colors';
@import 'variables/theme';

@import 'variables/variables';

@import './fonts';

// @import 'animations';

.button {
  border-radius: $default-border-radius;
  transition: all $transition-duration $transition-function;
  user-select: none;

  &:hover,
  &:active {
    color: $salem;

    .icon path {
      fill: $salem;
    }
  }

  &.primary {
    background-color: $ocean-green;
    color: $white;
    font-weight: 600;
    text-transform: uppercase;

    &:disabled {
      background-color: $alto;
      color: $white;
      cursor: not-allowed;
    }

    &:hover:enabled,
    &:active:enabled {
      background-color: $salem;
      color: $white;
    }
  }

  &.secondary {
    border: 1px solid $ocean-green;
    border-radius: $default-border-radius;
    color: $ocean-green;

    &:hover,
    &:active {
      background-color: $salem;
      color: $white;
    }
  }

  &.default {
    background-color: $dusty-gray;
    border: none;
    border-radius: $default-border-radius;
    color: $white;

    &:hover,
    &:active {
      background-color: $dove-gray;
      color: $white;
    }
  }

  &.menu-link {
    color: $white;
  }

  &.outlined {
    background-color: $white !important;
    border: 1px solid $ocean-green;
    border-radius: $default-border-radius;
    color: $ocean-green;

    &:hover,
    &:active {
      border-color: $salem;
      color: $salem;
    }

    &:disabled {
      border-color: $alto;
      color: $alto;
    }
  }

  &.new-primary {
    background-color: $aqua-forest;
    color: $white;
    font-size: 19px;
    line-height: 19px;
    border-radius: 50px;
    height: 46px;

    &:disabled {
      background-color: $alto;
      color: $white;
      cursor: not-allowed;
    }

    &:hover:enabled,
    &:active:enabled {
      background-color: $salem;
      color: $white;
    }
  }

  &.new-secondary {
    border: 1px solid $aqua-forest;
    border-radius: 50px;
    color: $aqua-forest;
    background-color: $white;

    &:hover,
    &:active {
      background-color: $salem;
      color: $white;
    }
  }
}

.link {
  color: $caribbean-green;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  user-select: text;
}

.link-inherit-size {
  font-size: inherit;
  line-height: inherit;
}

@mixin loading-animation ($color, $size) {
  width: $size;
  aspect-ratio: 1 / 1;
  border: 2px solid $color;
  border-top-color: $transparent;
  border-left-color: $transparent;
  border-radius: 50%;
  animation: rotate 1s infinite ease-in-out;
}

.new-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  transform-origin: bottom;
  transition: transform calc($transition-duration / 2), background-color $transition-duration;
  text-transform: uppercase;
  border-radius: 50px;
  border: 1px solid transparent;
  font-size: 1rem;
  font-family: "PapaSans-MediumCondensed";
  height: fit-content;
  width: fit-content;
  background-color: $grey-50;
  color: $grey-300;
  padding: 12px 16px;

  &.xsmall {
    font-size: 14px;
    padding: 8px 16px;
  }
  &.small {
    font-size: 16px;
    padding: 8px 16px;
  }
  &.medium {
    font-size: 19px;
    padding: 12px 16px;
  }
  &.large {
    font-size: 19px;
    padding: 16px 24px;
  }

  &.full-width {
    width: 100%;
  }

  &.fab {
    position: sticky;
    bottom: 1rem;
  }

  &:disabled {
    background-color: $white;
    color: $grey-100;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: $grey-100;
    color: $grey-500;
  }
  &:active:enabled {
    transform: scale(0.98);
    background-color: $grey-200;
    color: $grey-500;
  }

  &.primary {
    background-color: $basil-500;
    color: $white;

    &:disabled {
      background-color: $basil-100;
      color: $white;
    }
    &:hover:enabled {
      background-color: $basil-900;
      color: $white;
    }
    &:active:enabled {
      background-color: $basil-700;
      color: $white;
    }
  }

  &.secondary {
    background-color: $success-500;
    color: $white;

    &:disabled {
      background-color: $success-100;
      color: $white;
    }
    &:hover:enabled {
      background-color: $success-900;
      color: $white;
    }
    &:active:enabled {
      background-color: $success-700;
      color: $white;
    }
  }

  &.outlined {
    border-color: $basil-500;
    background-color: $white;
    color: $basil-500;

    &:disabled {
      border-color: $basil-100;
      background-color: $white;
      color: $basil-100;
    }
    &:hover:enabled {
      border-color: $basil-900;
      background-color: $white;
      color: $basil-900;
    }
    &:active:enabled {
      border-color: $basil-700;
      background-color: $white;
      color: $basil-700;
    }
  }

  &.empty {
    border-color: transparent;
    background-color: $white;
    color: $basil-500;

    &:disabled {
      color: $basil-100;
    }
    &:hover:enabled {
      background-color: $basil-50;
      color: $basil-900;
    }
    &:active:enabled {
      background-color: $basil-100;
      color: $basil-900;
    }
  }

  &.link {
    display: inline-block;
    font-family: "PapaSans-Regular";
    padding: 0px;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }

    text-decoration: none;
    background-color: transparent;
    color: $success-500;
    &.medium {
      font-size: 16px;
    }
    &:disabled {
      background-color: transparent;
      color: $success-100;
    }
    &:hover:enabled {
      text-decoration: underline;
      background-color: transparent;
      color: $success-900;
    }
    &:active:enabled {
      text-decoration: underline;
      background-color: transparent;
      color: $success-900;
    }
  }

  &.danger {
    background-color: $error-500;
    color: $white;

    &:disabled {
      background-color: $error-100;
      color: $white;
    }
    &:hover:enabled {
      background-color: $error-900;
      color: $white;
    }
    &:active:enabled {
      background-color: $error-700;
      color: $white;
    }
  }

  &.google {
    background-color: $google;
    color: $white;

    &:disabled {
      background-color: lighten($google, 30%);
      color: $white;
    }
    &:hover:enabled {
      background-color: darken($google, 10%);
      color: $white;
    }
    &:active:enabled {
      background-color: darken($google, 5%);
      color: $white;
    }
  }

  &.facebook {
    background-color: $facebook;
    color: $white;

    &:disabled {
      background-color: lighten($facebook, 30%);
      color: $white;
    }
    &:hover:enabled {
      background-color: darken($facebook, 10%);
      color: $white;
    }
    &:active:enabled {
      background-color: darken($facebook, 5%);
      color: $white;
    }
  }

  &.icon {
    color: $basil-500;
    padding: 8px;
    &.xsmall {
      padding: 4px;
    }

    &.small {
      padding: 6px;
    }

    &.medium {
      padding: 8px;
    }

    &.large {
      padding: 12px;
    }

    &-accent {
      @extend .icon;

      background-color: $pepperoncini-500;
      color: $basil-500;

      &:disabled {
        background-color: $basil-100;
        color: $basil-500;
      }
      &:hover:enabled {
        background-color: $pepperoncini-400;
        color: $basil-500;
      }
      &:active:enabled {
        background-color: $pepperoncini-700;
        color: $white;
      }
    }
  }

  &.left {
    ion-icon {
      position: absolute;
      margin-top: auto;
      margin-bottom: auto;
      left: 16px;
      text-align: center;
    }
  }
}
