@import 'variables/variables';
@import 'variables/breakpoints';
@import 'variables/fonts';
@import 'variables/colors';

@import "variables/_fonts";

.dropdown-text {
  font-size: 15px;
}

.title-highlighted {
  color: $thunderbird;
  font-size: 45px;
  font-weight: bold;
  line-height: 56px;
}

.section-title {
  color: $tundora;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.3;
}

.subtitle-big {
  color: $tundora;
  font-size: 30px;
  font-weight: 600;
  line-height: 56px;
}

.subtitle-medium {
  font-size: 22px;
  font-weight: bold;
  line-height: 25px;
}

.subtitle-medium-semibold {
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
}

.subtitle-small {
  color: $tundora;
  color: inherit;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.subtitle-small-light {
  color: $tundora;
  color: inherit;
  font-size: 16px;
  line-height: 19px;
}

.subtitle-small-medium {
  color: $tundora;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.text-alto {
  color: $alto;
}

.text-highlighted {
  font-size: 18px;
  line-height: 23px;
}

.text-base {
  font-size: 15px;
  line-height: 19px;
}

.text-light {
  font-weight: 300;
}

.text-menu-link {
  @extend .text-base;

  font-weight: 600;
  transition: text-decoration $transition-duration $transition-function;
}

.text-detail-tab {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.text-select {
  font-size: 13px;
  line-height: 1;
}

.text-price {
  @extend .text-select;

  color: $ocean-green;
  font-weight: 600;
}

.text-white {
  color: $white;
}

.text-caribbean-green {
  color: $caribbean-green;
}

.text-ocean-green {
  color: $ocean-green;
}

.text-dove-gray {
  color: $dove-gray;
}

.text-boulder {
  color: $boulder;
}

.text-dusty-gray {
  color: $dusty-gray;
}

.text-emperor {
  color: $emperor;
}

.text-red {
  color: $red;
}

.text-silver {
  color: $silver;
}

.text-thunderbird {
  color: $thunderbird;
}

.text-salem {
  color: $salem;
}

.text-thundora {
  color: $thundora;
}

.text-aqua-forest{
  color: $aqua-forest
}

.text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-small {
  font-size: 14px;
  line-height: 18px;
}

.text-medium-condensed {
  font-family: 'PapaSans-MediumCondensed';
  font-weight: 500 !important;
  text-transform: uppercase;
}

.papa-bold {
  @extend .bold;
  color: $ocean-green;
  font-size: 28px;
  line-height: 28px;

  @include md {
      font-size: 22px;
  }
}

.text-papa-Heavy{
  font-family: 'PapaSans-Heavy';
}

.text-papa-regular,
.papa-sans-regular {
  font-family: 'PapaSans-Regular';
}

.text-papa-medium-condensed {
  font-family: 'PapaSans-MediumCondensed';
}

.papa-sans-bold {
  font-family: 'PapaSans-Bold';
}

@media screen and (max-width: $medium-device) {
  .subtitle-big {
    font-size: 28px;
  }
}

@media screen and (max-width: $small-device) {
  .dropdown-text {
    color: $white;
    font-size: 14px;
  }

  .subtitle-big {
    font-size: 22px;
    line-height: 30px;
  }

  .subtitle-medium {
    font-size: 18px;
    line-height: 20px;
  }

  .subtitle-small {
    font-size: 14px;
  }

  .text-base {
    font-size: 14px;
  }

  .text-highlighted {
    font-size: 15px;
    line-height: 20px;
  }

  .title-highlighted {
    font-size: 30px;
    line-height: 40px;
  }

  .text-select {
    font-size: 14px;
  }

  .text-xs{
    font-size: 13px;
  }
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-capitalize-first-letter {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}

.text-capitalize-first-letter {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}

.text-justify {
  text-align: justify;
}

.line-through {
  text-decoration: line-through;
}

@mixin max-lines($max) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $max;
  -webkit-box-orient: vertical;
}

.papa-sans-title{
  @extend .text-medium-condensed;
  color: $cod-gray;
}

.papa-sans-description{
  font-family: 'PapaSans-Regular';
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: $scorpion;
}

.papa-sans-bold{
  font-family: 'PapaSans-Bold';
}
