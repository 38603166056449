$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$scale: 5;

@media (max-width: $breakpoint-tablet) {
  $scale: 5;
}

@media (min-width: $breakpoint-desktop) {
  $scale: 10;
}

$min-top: 5;
$max-top: 100;

$min-right: 5;
$max-right: 100;

$min-bottom: 5;
$max-bottom: 100;

$min-left: 5;
$max-left: 100;

// Top margins
@if $min-top > 0 {
  $unscaled-min-top: calc($min-top / $scale);
  $unscaled-max-top: calc($max-top / $scale);

  @for $i from $unscaled-min-top through $unscaled-max-top {
    .m-top-#{$i} {
      margin-top: #{$i * $scale}px;
    }
  }
}

// Right margins
@if $min-right > 0 {
  $unscaled-min-right: calc($min-right / $scale);
  $unscaled-max-right: calc($max-right / $scale);

  @for $i from $unscaled-min-right through $unscaled-max-right {
    .m-right-#{$i} {
      margin-right: #{$i * $scale}px;
    }
  }
}

// Bottom margins
@if $min-bottom > 0 {
  $unscaled-min-bottom: calc($min-bottom / $scale);
  $unscaled-max-bottom: calc($max-bottom / $scale);

  @for $i from $unscaled-min-bottom through $unscaled-max-bottom {
    .m-bottom-#{$i} {
      margin-bottom: #{$i * $scale}px;
    }
  }
}

// Left margins
@if $min-left > 0 {
  $unscaled-min-left: calc($min-left / $scale);
  $unscaled-max-left: calc($max-left / $scale);

  @for $i from $unscaled-min-left through $unscaled-max-left {
    .m-left-#{$i} {
      margin-left: #{$i * $scale}px;
    }
  }
}

.m-top-0 {
  margin-top: 0;
}

.m-right-0 {
  margin-right: 0;
}

.m-bottom-0 {
  margin-bottom: 0;
}

.m-left-0 {
  margin-left: 0;
}
