@import "src/scss/imports";

.new-button {
  @extend .new-button;

  &.selected {
    border: 1px solid $caribbean-green !important;
  }

  span {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    pointer-events: none;
  }

  .icon {
    height: 14px;
    width: auto;
    margin-right: 10px;
  }
}

.loading {
  border: 2px solid white;
  border-left-color: transparent;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: rotate 1s linear infinite;
  margin-right: 8px;

  &.large {
    width: 14px;
    height: 14px;
  }
}
