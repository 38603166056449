@import 'src/scss/imports.scss';

$animation-duration: 1.5s;

.container {
  @extend .row, .middle, .space-between, .m-bottom-3;
  padding: 10px 15px;
  gap: 12px;
  border-radius: 4px;
  border-radius: 4px;
  box-shadow: $box-shadow-smooth 0px 2px 8px 0px;
  cursor: pointer;
}

.loading {
  opacity: .7;
  pointer-events: none;
}

.pizza-icon {
  min-width: 42px;
}
.checkbox {
  height: 18px;
  width: 18px;
  min-width: 18px;
  background-color: $caribbean-green;
  cursor: pointer;
}

.promo-title {
  @extend .bold;
  color: $killarney;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
  font-size: 17px;
  font-family: 'PapaSans-Bold';
  margin-bottom: 5px;
  min-height: 16px;
  overflow: hidden;
}


.icon-container {
  background-color: $cornflower-blue;
  border-radius: 50%;
  padding: 5px;
  margin-right: 12px;
  max-height: 35px;
  max-width: 35px;
}

.icon-decoration {
  position: absolute;
  top: 0;
  right: 0;
}

.cross-button-container {
  width: auto !important;
  padding: 0 !important;
}

.cross-button-icon {
  border-radius: 50%;
  border: 1px solid $santas-gray;
}

.hidden {
  visibility: hidden;
}
