@import 'src/scss/imports.scss';

.container {
  @extend .row, .start, .space-between;
  gap: 8px;
  padding: 8px;
  border: 1px solid $gallery !important;
  border-radius: 4px;
  cursor: pointer;
}

.loading {
  opacity: .7;
  pointer-events: none;
}

.textApply {
  margin-right: auto;
}

.text-undo {
  font-size: 14px !important; 
}
