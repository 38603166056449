$breakpoint-xxs: 320px;
$breakpoint-xs: 450px;
$breakpoint-sm: 550px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1440px;
$breakpoint-xxxl: 1920px;
$breakpoint-height: 750px;
@mixin xxs {
  @media (max-width: #{$breakpoint-xxs}) {
    @content;
  }
}
@mixin xs {
  @media (max-width: #{$breakpoint-xs}) {
    @content;
  }
}
@mixin sm {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}
@mixin md {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}
@mixin lg {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}
@mixin xl {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: #{$breakpoint-xxl}) {
    @content;
  }
}
@mixin xxxl {
  @media (max-width: #{$breakpoint-xxxl}) {
    @content;
  }
}
@mixin short-height {
  @media (max-height: #{$breakpoint-height}) {
    @content;
  }
}
@mixin custom-breakpoint ($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}
@mixin custom-height-breakpoint ($breakpoint) {
  @media (max-height: #{$breakpoint}) {
    @content;
  }
}
@mixin rwd($screen) {
  @media (min-width: ($screen+'px')) {
    @content;
  }
}
