$transparent: transparent;
$white: #fff;
$black: #000;
$black-tooltip: #222;
$azure: #35599f;
$valencia: #d34836;

// White
$wild-sand: #f5f5f5;
$alabaster: #fafafa;

// Green
$light-green: #c9e5d9;
$ocean-green: #3fae7f;
$caribbean-green: #01be7f;
$salem: #128d59;
$green-dark: #008f6d;
$emerald: #47c757;
$narvik: #ebf8f2;
$scandal: #dafbe1;
$jewel: #1a7f37;
$skeptic: #c3e6d7;
$panache: #ecf7f2;
$green-pea: #236046;
$killarney: #2d5d2b;
$timber-green: #173127;
$amazon: #2d7c5a;
$cornflower-blue: #CFEB0C;
$como: #487257;
$killarney: #2D5D2A;
$aqua-forest: #65A17A;
$success-50: #F0F6F2;


// Gray
$mercury: #e7e7e7;
$alto: #d6d6d6;
$gallery: #EBEBEB;
$gallery-2: #767676;
$silver: #ccc;
$silver-light: #ddd;
$disabled-gray: #b1b1b1;
$boulder: #7d7d7d;
$dusty-gray: #9b9b9b;
$silver-chalice: #9d9d9d;
$emperor: #555454;
$thundora: #4a4a4a;
$very-dark-gray: #707070;
$santas-gray: #969bab;
$regent-gray: #86989e;
$dove-gray: #717171;
$xanadu: #7c7d7c;
$emperor-2: #545454;
$bright-gray: #ECECEC;
$arsenic: #414141;
$gray: #808080;
$tundora: #414141;
$scorpion: #5C5C5C;
$cod-gray: #151515;
$white-lilac: #FCF9FD;
$box-shadow-smooth: #63636333;
$mine-shaft: #333333;
$garlic-300: #ECD6F3;
$garlic-400: #E9CEF1;

// Red
$red: #ff0003;
$red-2: #f30000;
$pale-red: #ffe4e4;
$thunderbird: #ba151a;
$dark-tan: #7d0e14;
$red-light-1: #da4453;
$red-light-2: #bd2b39;
$red-light-3: #df2a27;
$pj-red: #ce3d33;
$pj-red-light: #f85145;
$pj-red-dark: #b22c23;
$piper: #CD5821;
$totem-pole: #942007;
$holy-smokies: #f8e8e8;
$flourishing-feelings: #d16266;
$flame-pea: #e15449;
$error: #ff0000;
$error-backgroud: #FFE6E6;
$lonestar: #710500;
$pipin: #FFE6E6;

// NPS Tooltip Gradient.
$agree-light: #73d78d;
$agree-dark: #009e5b;
$warning-light: #f1be2c;
$warning-dark: #ef7c13;
$danger-light: #fd7d5f;
$danger-dark: #fc3a3a;

//Yellow
$yellow: #f6bb42;
$yellow-dark: #ed9f00;
$yellow-mild: #ff9100;
$saffron: #f4d03f;
$pirate-gold: #c98800;
$sandy-brown: #f0ad4e;
$mellow-apricot: #f5b577;
$laser: #c1ac66;
$vivid-yellow: #CFEB0C;
$hint-of-yelow: #FAFDE7;
$astra: #F0F9B4;
$goldenrod: #FFCF6E;

// Warning
$warning-50: #fffaf1;
$warning-100: #fff0d2;
$warning-200: #ffe9bc;
$warning-300: #ffdf9e;
$warning-400: #ffd98b;
$warning-500: #ffcf6e;
$warning-600: #e8bc64;
$warning-700: #b5934e;
$warning-800: #8c723d;
$warning-900: #6b572e;

// Success
$success-100: #CFE2D6;
// Purple
$amour: #f7ecf9;
$carbon-grey: #5F5164;
