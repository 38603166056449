@font-face {
  font-family: 'PapaSans-Bold';
  src: url('../../app/assets/fonts/PapaSans-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'PapaSans-Heavy';
  src: url('../../app/assets/fonts/PapaSans-Heavy.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'PapaSans-Italic';
  src: url('../../app/assets/fonts/PapaSans-Italic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'PapaSans-MediumCondensed';
  src: url('../../app/assets/fonts/PapaSans-MediumCondensed.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'PapaSans-Regular';
  src: url('../../app/assets/fonts/PapaSans-Regular.woff2');
  font-display: swap;
}

$font: 'PapaSans-Regular', 'PapaSans-Heavy', 'PapaSans-Italic', 'PapaSans-MediumCondensed', 'PapaSans-Bold';
