@import './variables/variables';
@import 'variables/theme';

.column {
  display: flex;
  flex-direction: column;

  &.top {
    justify-content: flex-start;
  }

  &.middle {
    justify-content: center;
  }

  &.bottom {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.start {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.end {
    align-items: flex-end;
  }

  &.stretch {
    align-items: stretch;
  }

  .self-start {
    align-self: flex-start;
  }

  .self-center {
    align-self: center;
  }

  .self-end {
    align-self: flex-end;
  }

  .self-stretch {
    align-self: stretch;
  }

  &.reverse {
    flex-direction: column-reverse;
  }
}

.row {
  display: flex;
  flex-direction: row;

  &.top {
    align-items: flex-start;
  }

  &.middle {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  .self-top {
    align-self: flex-start;
  }

  .self-middle {
    align-self: center;
  }

  .self-bottom {
    align-self: flex-end;
  }

  .self-stretch {
    align-self: stretch;
  }
}

@for $i from 1 through 16 {
  .item-#{$i} {
    flex: $i;
    min-width: 0;
  }
}

@for $i from 1 through 16 {
  .item-#{$i}-no-basis {
    flex: $i 1 0;
    min-width: 0;
  }
}

@for $i from 1 through 16 {
  .grow-#{$i} {
    flex-grow: $i;
  }
}

.item-0 {
  flex: 0 1 auto;
}

.flex-hide-desktop {
  display: none;
}

.flex-hide-mobile {
  display: flex;
}

.force-center {
  left: -100%;
  right: -100%;
  top: -90%;
  bottom: -100%;
  margin: auto;
}

@media screen and (max-width: $small-device) {
  .flex-hide-desktop {
    display: flex;
  }

  .flex-hide-mobile {
    display: none;
  }
}

.ps-relative{
  position: relative;
}

.ps-absolute{
  position: absolute;
}

.super-z{
  z-index: 10000;
}

.inline {
  display: inline;
}

.divider {
  border-top: 1px solid $grey-50;
}
