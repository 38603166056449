@import 'src/scss/imports.scss';

$animation-duration: 1.5s;

.container {
  @extend .row, .middle, .space-between, .m-bottom-3;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: $narvik;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
  &:hover {
    border-color: $ocean-green;
  }
}

.pizza-icon {
  min-width: 42px;
}
.checkbox {
  height: 18px;
  width: 18px;
  background-color: $caribbean-green;
}

.title-container {
  @extend .text-medium-condensed;
  background-color: $astra;
  font-weight: 500;
  border-radius: 4px;
  line-height: 19px;
  font-size: 17px;
  color: $killarney;
} 

.description {
  @extend .bold;
  color: $killarney;
  flex-grow: 1;

  & span {
    @extend .bold;
    font-size: inherit;
    display: inline;
    color: inherit;
  }
}

.icon-container {
  background-color: $astra;
  border-radius: 50%;
  padding: 5px;
}

.icon-decoration {
  position: absolute;
  top: 0;
  right: 0;
}

.cross-button-container {
  width: auto !important;
  padding: 0 !important;
}

.cross-button-icon {
  border-radius: 50%;
  border: 1px solid $santas-gray;
}

.hidden {
  visibility: hidden;
}

.loading {
  opacity: .7;
  pointer-events: none;
}
