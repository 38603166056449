@import '~wolox-equalizer/equalizer';

@import 'variables/fonts';

@import 'animations';

@import 'icons';

@import 'layout';

@import 'margins';

@import 'components';

@import 'fonts';

@import 'buttons';

@import 'variables/variables';

@import 'inputs';

@import 'variables/breakpoints';

@import 'externals/weglot';

* {
  font-family: $font;
}

body {
  background-color: $white;
  color: $dove-gray;
  height: auto;
  margin: auto;
  padding: 0;
}

.hide {
  display: none;
}

.async-hide {
  opacity: 0 !important;
}

.container-promociones {
  display: grid;
  grid-template-columns: 3.3fr 1fr;
  grid-template-rows: 3.3fr 0fr;
  gap: 0px 15px;
  grid-auto-flow: row;
  grid-template-areas:
    ". .";
  img{
    max-width: 100%;
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }
  @include sm{
    display: flex;
    flex-flow: row wrap;
    .column{
      width: 100%;
    }
    .small-col{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 15px;
      grid-auto-flow: row;
      grid-template-areas:
        ". .";
    }
  }
}

.unselectable {
  user-select: none;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

@media screen and (max-width: $small-device) {
  *,
  *:before,
  *:after {
    user-select: none;
  }

  input,
  input::before,
  input::after,
  textarea,
  textarea::before,
  textarea::after {
    user-select: initial;
  }

  img {
    -webkit-touch-callout: none;
  }
}

.pj-logo {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: 50px;
}

%backdrop-properties {
  background-color: rgba($black, 0.3);
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
